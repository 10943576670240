import { useState } from 'react';
import Button from './baseButton';
import Api from '../../services/api';
import customIcons from '../../utils/icons';
import useToastStore from '../app-layout/toastStore';
import { ComponentProps } from '../../types/core.type';

type DownloadButtonProps = ComponentProps & {
  label?: string;
  endpoint: string
  data: any;
};

export default function DownloadButton({
  id = '',
  className = '',
  label = 'Export CSV',
  endpoint,
  data,
}: DownloadButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async (e: any) => {
    setIsLoading(true);
    try {
      const response = await Api.post(endpoint, data);
      e.preventDefault();
      window.open(response, '_blank');
      useToastStore.getState().showToast('Success! Please check your downloads folder', 'success');
    } catch (error) {
      useToastStore.getState().showToast(error instanceof Error ? error.message : 'An error occurred', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      id={`${id}-download-button`}
      className={className}
      onClick={handleDownload}
    >
      {isLoading
        ? <span className="mx-1">Loading...</span> : (
          <>
            <i>{customIcons.downloadIcon}</i>
            <span className="mx-1">{label}</span>
          </>
        )}
    </Button>
  );
}
