import { LuLoader } from 'react-icons/lu';

export default function LoadingFallback({ message = '...Loading' }: { message:string }) {
  return (
    <div className="flex items-center justify-center p-4 space-x-2">
      <LuLoader className="animate-spin w-5 h-5  text-blue-500" />
      <span className="text-gray-600">{message}</span>
    </div>
  );
}
