import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface FilterStoreState {
  selectedFilters: { [title: string]: { [key: string]: string[] } };
  setSelectedFilters: (title: string, dropdownKey: string, options: string[]) => void;
  clearAllFilters: () => void;
  getActiveFilters: () => Record<string, any>;
  prepareFilters: () => string[];
}

const sessionStorageName = 'filter-store';
const useFilterStore = create<FilterStoreState>()(
  // persist store state in sessionStorage
  persist(
    (set, get) => ({
      selectedFilters: {},
      setSelectedFilters: (title, dropdownKey, options) => set((state) => ({
        selectedFilters: {
          ...state.selectedFilters,
          [title]: {
            ...state.selectedFilters[title],
            [dropdownKey]: options,
          },
        },
      })),

      clearAllFilters: () => {
        set(() => ({
          selectedFilters: {},
        }));
        sessionStorage.removeItem(sessionStorageName);
      },

      getActiveFilters: () => {
        const state = get();
        const result: Record<string, any> = {};
        Object.entries(state.selectedFilters).forEach(([title, dropdowns]) => {
          if (!result[title]) {
            result[title] = {};
          }
          Object.entries(dropdowns).forEach(([key, values]) => {
            if (values.length > 0) {
              result[title][key] = [...values];
            }
          });
        });

        return result;
      },

      prepareFilters: () => {
        const state = get();
        return Object.values(state.selectedFilters)
          .flatMap((dropdowns) => Object.values(dropdowns))
          .flat();
      },
    }),
    {
      name: sessionStorageName,
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useFilterStore;
