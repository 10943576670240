import { CampaignImpactResponse } from '../../api-models/types.gen';
import { formatDateToString, formatSnapshot } from '../../utils/formatters';
import { DisplayCampaignSummary } from '../campaigns.type';
import { HTTPMethod } from '../enums';
import { AdapterInterface } from '../global';
import BaseAdapter from './baseAdapter';

export default class PostCampaignSummary extends BaseAdapter implements AdapterInterface {
  rawResponse: CampaignImpactResponse;

  async adaptResponse(campaignId: number): Promise<DisplayCampaignSummary> {
    await this.fetchApiResponse('/api/pension-credit-campaign-summary', HTTPMethod.Post, { id: campaignId });
    return this.displayResponse();
  }

  displayResponse() {
    // TODO: refactor response structure and formatting - add display text to backend response LIF:1604

    const formatReportEnds = () => {
      const dateSent = this.rawResponse.campaign_details.date_sent;
      if (dateSent == null) return 'Not Dispatched';

      const date = new Date(dateSent);
      date.setMonth(date.getMonth() + 6);
      return date.toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    };

    return {
      id: this.rawResponse.campaign_details.id ?? 0,
      reportEnds: `Report closes on: ${formatReportEnds()}`,
      campaignDetails: [
        { text: 'Campaign Name', value: this.rawResponse.campaign_details.name },
        { text: 'Data from', value: formatSnapshot(this.rawResponse.campaign_details.snapshot, 'long') },
        {
          text: 'Campaign Sent on',
          value: this.rawResponse.campaign_details.date_sent
            ? formatDateToString(new Date(this.rawResponse.campaign_details.date_sent)) : 'Not Dispatched',
        },
        { text: 'Communications Channel', value: this.rawResponse.campaign_details.channel === 'print_and_post' ? 'Letter' : 'SMS' },
      ],
      campaignImpact: [
        { text: 'Total Households Contacted', value: this.rawResponse.campaign_details.number_of_recipients.toString() },
        { text: 'Total Households Who Have Claimed', value: this.rawResponse.campaign_impact.number_of_households_claiming.toString() },
        { text: 'Total Carer households', value: this.rawResponse.campaign_impact.number_of_carer_households.toString() },
        { text: 'Annual Impact', value: this.rawResponse.campaign_impact.annual_value },
        { text: 'Lifetime Impact', value: this.rawResponse.campaign_impact.lifetime_value },
      ],
      graphDetails: {
        title: this.rawResponse.graph_details.title,
        categoricalLabel: this.rawResponse.graph_details.categorical_label,
        valueLabel: this.rawResponse.graph_details.value_label,
        data: this.rawResponse.graph_details.data,
      },
    };
  }
}
