import Api from '../../services/api';
import useToastStore from '../app-layout/toastStore';
import customIcons from '../../utils/icons';
import Button from './baseButton';
import { adminAccess } from '../../utils/authRoleWrap';

function SendToCalculatorButton({ data, className }: { data: any, className: string }) {
  const showToast = useToastStore((state) => state.showToast);

  const sendDataToCalculator = async (filteredData: any) => {
    try {
      const response = await Api.post('/api/households-to-calculator', filteredData);
      showToast(response, 'success');
    } catch (error) {
      showToast(error instanceof Error ? error.message : 'An error occurred', 'error');
    }
  };

  return (
    <Button
      id="send-to-calculator-button"
      onClick={() => sendDataToCalculator(data)}
      className={className}
    >
      <i>{customIcons.calculatorIcon}</i>
      <span>Send to Calculator</span>
    </Button>
  );
}
export default adminAccess(SendToCalculatorButton);
