import { CampaignSmsTemplateResponse, SmsTemplateResponse } from '../../api-models/types.gen';
import { SMSSenderInformation } from '../campaigns.type';
import { HTTPMethod } from '../enums';
import { AdapterInterface } from '../global';
import BaseAdapter from './baseAdapter';

export default class GetSmsTemplates extends BaseAdapter implements AdapterInterface {
  rawResponse: SmsTemplateResponse;

  async adaptResponse(): Promise<SMSSenderInformation> {
    await this.fetchApiResponse('/api/all-sms-templates', HTTPMethod.Get);
    const smsTemplates = this.rawResponse.sms_template_information.map((template: CampaignSmsTemplateResponse) => ({
      id: template.sms_template_id ?? 0,
      template: template.template,
      organisationId: template.organisation_id,
    }));
    return {
      smsSenderId: this.rawResponse.sms_sender_id,
      smsTemplates,
    };
  }
}
