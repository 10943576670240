import useCampaignStore from '../components/campaigns/campaignStore';
import { CampaignSelection, CampaignStore } from '../types/campaigns.type';

export const createCampaign = async (campaignInformation: CampaignSelection): Promise<CampaignStore> => {
  const id = Math.random().toString(36).substring(2, 9);

  const campaign = {
    id,
    createdAt: Date.now(),
    status: 'draft',
    snapshot: campaignInformation.snapshot,
    campaignName: campaignInformation.campaignName,
    campaignType: campaignInformation.campaignType,
    communicationChannel: campaignInformation.communicationChannel,
    excludeHouseholds: campaignInformation.excludeHouseholds,
    excludeHouseholdsByMonth: campaignInformation.excludeHouseholdsByMonth,
    stagger: campaignInformation.stagger,
  };

  const { addCampaign } = useCampaignStore.getState();
  addCampaign(campaign);

  return campaign;
};

export const getCampaign = (id: string): CampaignStore => {
  const { campaigns } = useCampaignStore.getState();
  const campaignData = campaigns.find((campaign) => campaign.id === id) as CampaignStore;

  if (!campaignData) {
    throw new Error('Campaign not found');
  }
  return campaignData;
};
