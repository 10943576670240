import { LetterTemplateRequestContent, LetterTemplateResponse } from '../../api-models/types.gen';
import { snakeCase } from '../../utils/formatters';
import { LetterTemplate } from '../campaigns.type';
import { HTTPMethod } from '../enums';
import { AdapterInterface } from '../global';
import BaseAdapter from './baseAdapter';

export default class PostLetterTemplates extends BaseAdapter implements AdapterInterface {
  rawResponse: LetterTemplateResponse[];

  modifiedRequest: LetterTemplateRequestContent;

  async adaptResponse(request: string): Promise<LetterTemplate[]> {
    const formattedRequest = this.adaptRequest(request);
    await this.fetchApiResponse('/api/all-letter-templates', HTTPMethod.Post, formattedRequest);

    const filteredTemplates = this.rawResponse.filter((template) => !template.letter_template_signed_url.toLowerCase().endsWith('.docx'));
    return filteredTemplates.map((template) => ({
      id: template.id,
      letterTemplateSignedUrl: template.letter_template_signed_url,
      displayText: PostLetterTemplates.createDisplayText(template.letter_template_signed_url),
    }));
  }

  static createDisplayText(url: string): string {
    const urlArray = new URL(url).pathname.split('/');
    const fileName = urlArray.pop()?.split('.')[0] ?? '';
    const benefitName = urlArray.pop()?.split('_').join(' ').replace(/(^\w|\s\w)/g, (benefit) => benefit.toUpperCase()) ?? '';
    return `${benefitName}: ${fileName}`;
  }

  adaptRequest(request: string): LetterTemplateRequestContent {
    this.modifiedRequest = { benefit_name: snakeCase(request) };
    return this.modifiedRequest;
  }
}
