import {
  ReactNode, useEffect, useMemo, useState,
} from 'react';
import { AuthContext } from '../../../utils/auth';
import LoadingModal from '../../loadingModal';
import Api from '../../../services/api';
import { FullUserProfile } from '../../../api-models';
import Role from '../../../types/enums';
import { User } from '../../../types/core.type';

export default function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const values = useMemo(() => ({ user, setUser }), [user]);

  const getUser = async (): Promise<FullUserProfile> => {
    try {
      const userResponse = await Api.get('/api/session/user');
      return userResponse;
    } catch (error) {
      throw new Error('Failed to fetch user data');
    }
  };

  useEffect(() => {
    const initializeUser = async () => {
      try {
        const userData = await getUser();

        if (userData) {
          const formatUserData = {
            email: userData.email,
            organisation: userData.temporary_organisation_name || userData.organisation_name,
            role: Role[userData.temporary_role_name as keyof typeof Role] || Role[userData.role_name as keyof typeof Role],
            super: userData.role_name === Role.SuperAdmin,
          };
          setUser(formatUserData);
        }
      } catch (error) {
        if (error instanceof Error) {
          setUser(null);
        }
        if (error && typeof error === 'object' && 'detail' in error && typeof error.detail === 'string') {
          setUser(null);
        }
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    initializeUser();
  }, [setUser]);

  if (loading) {
    return <LoadingModal message="...loading" />;
  }

  if (!loading && user === null) {
    console.error('Internal error: no user found');
    window.location.href = '/login';
    return null;
  }

  return (
    <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
  );
}
