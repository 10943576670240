export default function sanitize(userInput: string): string {
  if (typeof userInput === 'string') {
    // removing < and > from the string
    return userInput.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  } return '';
}
export const formatSnapshot = (snapshot: string, longOrShort: 'long' | 'short'): string => {
  const firstDate = `20${snapshot.slice(2, 4)}-${snapshot.slice(0, 2)}-01`;
  return new Date(firstDate).toLocaleString('default', { month: longOrShort, year: 'numeric' });
};

export const snakeCase = (word: string) => word
// Prefix captial letters with undersore
  .replace(/([A-Z])/g, '_$1')
// Replace spaces with underscores
  .replace(/ /g, '_')
// Make lowercase
  .toLowerCase()
// Remove any leading underscore were added
  .replace(/^_/, '')
// Remove duplicate underscores and replace with single underscore
  .replace(/_+/g, '_');

export const formatDateToString = (date: Date): string => { const newDate = new Date(date); return newDate.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }); };

export const displayCase = (key: string): string => {
  const joiningWords = ['a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'as', 'at',
    'by', 'for', 'from', 'in', 'into', 'near', 'of', 'on', 'onto',
    'to', 'with'];
  const joiningWordsSet = new Set(joiningWords);

  // replace underscores with spaces
  let result = key.replace(/_/g, ' ');
  // add space before capital letters
  result = result.replace(/([A-Z])/g, ' $1');
  result = result.replace(/([0-9])/g, ' $1');
  result = result.replace(/\s+/g, ' ').trim();

  return result.split(' ')
    .map((word, index) => {
      const lowerWord = word.toLocaleLowerCase();
      if (index === 0 || !joiningWordsSet.has(lowerWord)) {
        return word.charAt(0).toUpperCase() + lowerWord.slice(1);
      }
      return lowerWord;
    })
    .join(' ');
};

export const createDisplayObject = <T extends Record<string, any>, R = T>(object: T): R => {
  const result = {} as R;

  Object.entries(object).forEach(([key, value]) => {
    (result as Record<string, any>)[displayCase(key)] = value;
  });

  return result;
};
