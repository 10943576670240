import { useState, useEffect } from 'react';
import useAuth from '../utils/auth';
import ViewerPanel from '../components/settings/viewerPanel';
import SuperAdminDashboard from '../components/settings/superAdminPanel';
import AdminDashboard from '../components/settings/orgAdminPanel';
import GetOrganisationUsers from '../types/adapters/organisationUsers';
import { OrganisationTableProps } from '../types/core.type';

export default function Settings() {
  const [organisationUsers, setOrganisationUsers] = useState<Array<OrganisationTableProps>>([]);
  const [user, setUser] = useState({});

  const authUser = useAuth();

  useEffect(() => {
    const getOrganisationUsers = async () => {
      try {
        const organisationUserInstance = new GetOrganisationUsers();
        const response = await organisationUserInstance.adaptResponse();
        if (!Array.isArray(response)) return;
        const currentUser = response.find((responseUser: OrganisationTableProps) => responseUser.email === authUser.email);
        if (currentUser) {
          setUser(currentUser);
        }
        const formattedResponse = response.filter((userResponse: OrganisationTableProps) => userResponse.email !== authUser.email);

        setOrganisationUsers(formattedResponse);
      } catch (error) {
        if (error instanceof Error) {
          throw new Error("False to fetch organisation's users");
        }
      }
    };

    getOrganisationUsers();
  }, [authUser.email]);
  return (
    <div className="flex flex-col w-full p-4 overflow-auto">
      {Object.keys(user).length > 0 ? <ViewerPanel user={user} /> : ''}
      <AdminDashboard tableData={organisationUsers} />
      <SuperAdminDashboard />
    </div>

  );
}
