import { TemporaryOrganisationRoleResponse } from '../../api-models/types.gen';
import { ViewAsResponse } from '../core.type';
import { HTTPMethod } from '../enums';
import { AdapterInterface } from '../global';
import BaseAdapter from './baseAdapter';

export default class PostViewAs extends BaseAdapter implements AdapterInterface {
  rawResponse: TemporaryOrganisationRoleResponse;

  async adaptResponse(data: { role: string, organisation: string }): Promise<ViewAsResponse> {
    await this.fetchApiResponse('/api/view-as', HTTPMethod.Post, { role_name: data.role, display_name: data.organisation });
    return {
      temporaryOrganisationName: this.rawResponse.temporary_organisation_name,
      temporaryRoleName: this.rawResponse.temporary_role_name,
    };
  }
}
