import customIcons from '../../utils/icons';

export default function SMS({ sender, message }: { sender: string, message: string | null | undefined }) {
  const fallBackMessage = (
    <div className="flex flex-col items-center justify-center h-full text-center">
      <h3 className="mx-2 p-2 font-bold text-pipgreen-dark">SMS Template Not Found</h3>
      <p className="max-w-md px-4">It looks like we do not have your template on file. Please contact your account manager or email helpdesk@policyinpractice.co.uk</p>
    </div>
  );
  const now = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const SMSPreview = (
    <div id="sms-preview-container" className="flex flex-col items-center h-full m-2">

      <div className="w-[320px] h-[640px] bg-slate-900 rounded-[2rem] shadow-xl p-4 relative overflow-hidden border">
        <svg
          className="absolute top-0 left-1/2 transform -translate-x-1/2 h-10 w-36"
          viewBox="0 0 144 24"
          preserveAspectRatio="none"
        >
          <path
            d="M0 0h144v14c0 5.523-4.477 10-10 10H10C4.477 24 0 19.523 0 14V0z"
            fill="#0f172a"
          />
        </svg>

        <div
          id="mobile-preview-texts-container"
          className="mt-2 h-[98%] overflow-y-auto bg-pipbg-mobile rounded-xl border border-pipgrey-light"
        >
          <div className="bg-white h-6 flex justify-between items-center px-2 text-sm">
            <span>{now}</span>
            <div className="flex space-x-1">
              <span>5G</span>
              <span>100%</span>
            </div>
          </div>
          <div id="mobile-preview-sender-header" className="border-b border-gray-200 pt-2 pb-4 px-4 bg-white">
            <div className="flex flex-col items-center space-x-2">
              <span>{customIcons.shieldCheckIcon}</span>
              <h2 className="font-medium text-md">{sender}</h2>
            </div>
          </div>

          <div id="mobile-preview-sms" className="p-4">
            <p className="bg-gray-200 rounded-2xl px-4 py-3 whitespace-pre-wrap">
              {message}
            </p>
            <p id="mobile-preview-delivered-status" className="text-xs text-gray-500 mt-2">
              {`Delivered • ${now} AM`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    message ? SMSPreview : fallBackMessage
  );
}
