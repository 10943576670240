import {
  CampaignDetailsResponse, CampaignImpactResponse, CampaignSmsTemplateResponse, DisplayUser, FullUserProfile,
  LetterTemplateResponse, SnapshotResponse, SmsTemplateResponse, TemporaryOrganisationRoleResponse, ImpactThemeResponse,
} from '../../api-models';
import { HTTPMethod } from '../enums';
import { BaseAdapterInterface } from '../global';

export default class BaseAdapter implements BaseAdapterInterface {
  rawResponse: SnapshotResponse | CampaignSmsTemplateResponse[] | LetterTemplateResponse[] | number
  | FullUserProfile | CampaignDetailsResponse[] | SmsTemplateResponse | CampaignImpactResponse | DisplayUser[]
  | TemporaryOrganisationRoleResponse | ImpactThemeResponse;

  async fetchApiResponse(endpoint: string, method: HTTPMethod, data?: any) {
    // RequestInit details: https://developer.mozilla.org/en-US/docs/Web/API/RequestInit
    const requestOptions: RequestInit = {
      method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (data) {
      requestOptions.body = JSON.stringify(data);
    }

    const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}${endpoint}`, requestOptions);
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.detail);
    }
    this.rawResponse = await response.json();
  }
}
