export default function ErrorFallback({ errorMessage }: { errorMessage: Error }) {
  const { message } = errorMessage;
  return (
    <div
      className="flex items-right justify-center text-right rounded-md"
      role="alert"
      aria-live="polite"
    >
      <p className="max-w-md px-4 text-piptext-error">
        {message || 'An error occurred'}
      </p>
    </div>
  );
}
