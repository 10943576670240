export default function Chip({ text, colour }: { text: string, colour: string }) {
  // the below to be expanded with more colours
  const colours: Record<string, string> = {
    green: 'chip-green',
    blue: 'chip-blue',
    red: 'chip-red',
    purple: 'chip-purple',
    yellow: 'chip-yellow',
  };
  const colourClass = colours[colour] || 'chip-default';
  return (

    <span
      title={text}
      className={`${colourClass} cursor-default chip`}
    >
      <span className="truncate">{text}</span>
    </span>
  );
}
