/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import Select, {
  components, PlaceholderProps, GroupBase, OptionProps, ValueContainerProps, MultiValue,
} from 'react-select';
import { OptionType } from '../../types/global';
import useFilterStore from '../streetview/filterStore';
import { snakeCase } from '../../utils/formatters';

const { ValueContainer, Placeholder, Option } = components;

// creates custom option component that has checkbox
function CustomOption(props: OptionProps<OptionType, true>) {
  const { isSelected, label } = props;
  return (
    <div>
      <Option {...props}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
          id={label}
          style={{ accentColor: '#002C50' }}
        />
        {' '}
        <label htmlFor={label}>{label}</label>
      </Option>
    </div>
  );
}
interface CustomValueContainerProps extends ValueContainerProps<OptionType, true, GroupBase<OptionType>> {
  children: React.ReactNode;
}

// creates custom value container that displays number of selected items
function CustomValueContainer({ children, ...props }: CustomValueContainerProps) {
  const { selectProps } = props;
  return (
    <ValueContainer {...props}>
      <Placeholder {...props as PlaceholderProps<OptionType, true, GroupBase<OptionType>>}>
        {Array.isArray(selectProps.value) && selectProps.value.length > 0
          ? `${selectProps.value.length} item(s) selected`
          // issue: this doesn't go away when you start typing
          : ''}
      </Placeholder>
      {React.Children.map(children, (child) => (child && (child as any).type !== Placeholder ? child : null))}
    </ValueContainer>
  );
}

export default function MultiCheckboxSelect({
  mutliSelectOptions, label, title,
}: { mutliSelectOptions: MultiValue<OptionType>, label: string, title: string }) {
  const selectedFilters = useFilterStore((state) => state.selectedFilters);
  const setSelectedFilters = useFilterStore((state) => state.setSelectedFilters);

  const cleanTitle = useMemo(() => snakeCase(title), [title]);
  const currentValues = useMemo(() => selectedFilters[cleanTitle]?.[label] || [], [selectedFilters, cleanTitle, label]);

  const selectedOptions = useMemo(() => mutliSelectOptions.filter((option: OptionType) => currentValues.includes(option.value)), [mutliSelectOptions, currentValues]);
  const handleChange = (selectedOptionsProps: MultiValue<OptionType>) => {
    const values = selectedOptionsProps ? selectedOptionsProps.map((option) => option.value) : [];
    setSelectedFilters(cleanTitle, label, values);
  };

  function customMultiValue() {
    return null;
  }
  return (
    <label htmlFor="multi-select" className="text-pipgrey font-thin text-sm">
      {label}
      <Select
        id="multi-select"
        isMulti
        options={mutliSelectOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? 'grey' : 'none',
            borderRadius: 10,
            margin: '0.5rem 0',
          }),
        }}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        value={selectedOptions}
        onChange={handleChange}
        components={{
          MultiValue: customMultiValue,
          ValueContainer: CustomValueContainer,
          Option: CustomOption,
        }}
      />
    </label>
  );
}
