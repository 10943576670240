import { CampaignDetails } from '../../api-models/types.gen';
import BaseAdapter from './baseAdapter';
import { AdapterInterface } from '../global';
import { Channel, HTTPMethod } from '../enums';
import { snakeCase } from '../../utils/formatters';
import { CampaignSelection } from '../campaigns.type';
/**
 * Adapter for handling PII data for eligible households.
 * @description This class manages the secure transmission of PII data for campaign eligible households.
 * @security Ensures proper formatting and handling of sensitive PII data.
 */
export default class PostEligibleHouseholdsPii extends BaseAdapter implements AdapterInterface {
  rawResponse: number;

  modifiedRequest: CampaignDetails;

  async adaptResponse(request: CampaignSelection): Promise<number> {
    const formattedRequest = this.adaptRequest(request);
    await this.fetchApiResponse('/api/eligible-households-with-pii', HTTPMethod.Post, formattedRequest);
    return this.rawResponse;
  }

  adaptRequest(request: CampaignSelection): CampaignDetails {
    if (!request.campaignName || !request.campaignType || !request.communicationChannel) {
      throw new Error('Missing required fields');
    }
    this.modifiedRequest = {
      campaign_name: request.campaignName,
      benefit_name: snakeCase(request.campaignType),
      snapshot: request.snapshot,
      channel_name: request.communicationChannel === Channel.Letter
        ? Channel.BackendLetter
        : request.communicationChannel.toLowerCase(),
      stagger: request.stagger, // default is set to false };
    };
    return this.modifiedRequest;
  }
}
