import { useLoaderData } from 'react-router-dom';
import FilterMenu from '../components/streetview/filterMenu';
import MapContainer from '../components/streetview/map';
import { FilterMenuData } from '../types/global';
import { MapApiResponse } from '../api-models/types.gen';
import Api from '../services/api';

export function loader() {
  return Api.post('/api/map-client', {});
}

export default function StreetView() {
  const data = useLoaderData() as MapApiResponse;
  const menu: FilterMenuData = {
    Demographics: [{
      type: '',
      label: 'filter 1',
      filterValues: [{ value: 'option1', label: 'option1' }, { value: 'option2', label: 'option2' }],
    },
    {
      type: '',
      label: 'filter 2',
      filterValues: [{ value: 'option1', label: 'option1' }, { value: 'option2', label: 'option2' }],
    },
    ],
    Benefits: [{
      type: '',
      label: 'filter 3',
      filterValues: [{ value: 'option1', label: 'option1' }, { value: 'option2', label: 'option2' }],
    },
    {
      type: '',
      label: 'filter 4',
      filterValues: [{ value: 'option1', label: 'option1' }, { value: 'option2', label: 'option2' }],
    },
    ],
    'Impact by Policy': [{
      type: '',
      label: 'filter 5',
      filterValues: [{ value: 'option1', label: 'option1' }, { value: 'option2', label: 'option2' }],
    },
    {
      type: '',
      label: '',
      filterValues: [{ value: 'option1', label: 'option1' }, { value: 'option2', label: 'option2' }],
    },
    ],
    'Finance & Debt': [
      {
        type: '',
        label: 'filter 6',
        filterValues: [{ value: 'option1', label: 'option1' }, { value: 'option2', label: 'option2' }],
      },
    ],
  };

  return (
    <div className="flex flex-col w-full h-screen overflow-auto md:flex-row">
      <MapContainer mapConfig={data} />
      <FilterMenu menu={menu} />
    </div>
  );
}
