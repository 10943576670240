import { CampaignDetailsResponse } from '../../api-models';
import { formatDateToString, formatSnapshot } from '../../utils/formatters';
import { DisplayCampaignInformation } from '../campaigns.type';
import { HTTPMethod } from '../enums';
import { AdapterInterface } from '../global';
import BaseAdapter from './baseAdapter';

export default class GetAllCampaigns extends BaseAdapter implements AdapterInterface {
  rawResponse: CampaignDetailsResponse[];

  async adaptResponse(): Promise<DisplayCampaignInformation[]> {
    await this.fetchApiResponse('/api/all-campaigns', HTTPMethod.Get);
    return this.displayResponse();
  }

  async displayResponse(): Promise<DisplayCampaignInformation[]> {
    return this.rawResponse.map((campaign: CampaignDetailsResponse) => ({
      'Campaign Name': campaign.name,
      'Number of Recipients': campaign.number_of_recipients,
      'LIFT Snapshot': formatSnapshot(campaign.snapshot, 'long'),
      'Campaign Type': campaign.benefit_name.split('_').join(' '),
      'Communication Channel': campaign.channel,
      'Created By': campaign.created_by,
      'Date Sent': campaign.date_sent ? formatDateToString(new Date(campaign.date_sent)) : '',
      Status: campaign.status,
      id: campaign.id,
    }));
  }
}
