import { create } from 'zustand';
import { Channel } from '../../types/enums';
import { CampaignSelection, CampaignStore } from '../../types/campaigns.type';

interface CampaignStoreState {
  campaigns: CampaignStore[];
  selected: CampaignSelection;
  setCampaigns: (campaigns: CampaignStore[]) => void;
  addCampaign: (campaign: CampaignStore) => void;
  setSelected: (title: string, value: string | boolean) => void;
  setTemplate: (campaignId: string, tempalteId: string | number) => void;
}

const useCampaignStore = create<CampaignStoreState>((set, get) => ({
  campaigns: [],
  selected: {
    campaignType: '',
    communicationChannel: '',
    campaignName: '',
    snapshot: '',
    excludeHouseholds: false,
    stagger: false,
  },
  smsTemplateId: null,
  letterTemplateId: null,
  setCampaigns: (campaigns) => set(() => ({
    campaigns,
  })),
  addCampaign: (campaign) => set((state) => ({
    campaigns: [campaign, ...state.campaigns],
  })),
  setSelected: (title, value) => set((state) => ({
    selected: { ...state.selected, [title]: value },
  })),
  setTemplate: (campaignId, templateId) => {
    const { campaigns } = get();

    const campaign = campaigns.find((c) => c.id === campaignId);
    if (!campaign) throw new Error('Campaign not found');
    if (campaign.communicationChannel === Channel.SMS) {
      set({
        campaigns: campaigns.map((c) => (c.id === campaignId
          ? { ...campaign, smsTemplateId: templateId as number } : campaign)),
      });
    } else {
      set({
        campaigns: campaigns.map((campaignWithLetterTemplateId) => (campaignWithLetterTemplateId.id === campaignId
          ? { ...campaign, letterTemplateId: templateId as string } : campaign)),
      });
    }
  },
}));

export default useCampaignStore;
