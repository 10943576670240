import { useState, MouseEvent } from 'react';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { useNavigate } from 'react-router-dom';
import customIcons from '../../utils/icons';
import DownloadButton from '../buttons/downloadButton';
import Button from '../buttons/baseButton';
import SendToCalculator from '../buttons/sendToCalculator';
import useOutsideClick from '../../hooks/useOutsideClick';
import { MapApiResponse } from '../../api-models';

export default function MapContainer({ mapConfig }: { mapConfig: MapApiResponse }) {
  const [mapType, setMapType] = useState('Dot map');
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleOutsideClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };
  const containerRef = useOutsideClick(handleOutsideClick);
  const toggleDropdown = () => {
    setIsOpen((previousState) => !previousState);
  };
  const handleMapTypeChange = (e: MouseEvent<HTMLButtonElement>) => {
    setMapType(e.currentTarget.value);
  };

  return (
    <div
      id="map-header-container"
      className="relative flex flex-col w-full transition-all duration-500 ease-in-out order-first"
    >
      <header className="relative h-16 flex items-center justify-between shadow">
        <ul className="flex flex-row m-2 text-xs bg-pipbg-light border border-pipgrey-border rounded-lg">
          <li className={`w-fit px-4 py-2 rounded-md ${mapType === 'Dot map' ? 'bg-pipgreen-light text-white' : ''}`}>
            <button type="button" value="Dot map" onClick={(e) => handleMapTypeChange(e)}>Dot map</button>
          </li>
          <li className={`w-fit px-4 py-2 rounded-md ${mapType === 'Ward map' ? 'bg-pipgreen-light text-white' : ''}`}>
            <button type="button" value="Ward map" onClick={(e) => handleMapTypeChange(e)}>Ward map</button>
          </li>
        </ul>
        <div
          id="action-dropdown"
          className="max-w-200 m-2 py-1 px-2 rounded-lg border"
          ref={containerRef}
        >
          <Button
            id="map-actions-dropdown-button"
            className=""
            label="Actions"
            icon={customIcons.optionIcon}
            onClick={toggleDropdown}
          >
            <i className={`text-pipgreen-light fas ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
          </Button>
        </div>
        {isOpen && (
        <div id="action-dropdown-menu" className="absolute right-2 mt-40 z-10 w-52 bg-white border border-pipgrey-border rounded borrounded-lg">
          <ul className="flex flex-col text-sm  font-thin [&>li:last-child]:rounded-b [&>li:first-child]:rounded-t [&>li]:border-pipgrey-border [&>li]:p-2 [&>li:hover]:bg-pipgrey-light">
            <li className="border-b">
              <SendToCalculator data="" />
            </li>
            <li className="border-b">
              <Button
                id="create-new-campaign-button"
                label="Create new campaign"
                icon={customIcons.sendIcon}
                onClick={() => navigate('/campaigns/new')}
              />
            </li>
            <li>
              <DownloadButton
                id="map-action"
                data={{ data: '' }}
                endpoint="/api/temporary-csv"
              />
            </li>
          </ul>
        </div>
        )}
      </header>

      <div id="map" className="h-full flex flex-col items-center ml-1">
        <APIProvider apiKey={mapConfig.key}>
          <Map
            style={{ width: '100%', height: '100%' }}
            defaultCenter={{ lat: mapConfig.lat, lng: mapConfig.lng }}
            defaultZoom={5.9}
            gestureHandling="greedy"
            disableDefaultUI
          />
        </APIProvider>
        <div
          id="headline-summary"
          className="absolute mt-4 h-16 w-1/2 flex items-center justify-center bg-pipbg-light bg-opacity-70 border border-pipgrey-border rounded-lg"
        >
          <ul className="flex flex-row space-x-2 divide-x-2 divide-pipgrey-border">
            <li className="p-2" />
          </ul>
        </div>
      </div>
    </div>
  );
}
