import { useState } from 'react';
import SubFilterMenu from './subFilterMenu';
import { FilterMenuData } from '../../types/global';
import customIcons from '../../utils/icons';
import useFilterStore from './filterStore';
import Button from '../buttons/baseButton';
import Modal from '../modal';

export default function FilterMenu({ menu }: { menu: FilterMenuData }) {
  const [expandedMenu, setExpandedMenu] = useState<Record<string, boolean>>({});
  const [selectedFilterGroup, setSelectedFilterGroup] = useState<string>('');
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const { prepareFilters, clearAllFilters } = useFilterStore();
  const toggleSubmenu = (menuItem: keyof typeof menu | '') => {
    if (selectedFilterGroup === menuItem) {
      // clicking the same item will collapse the submenu
      setSelectedFilterGroup('');
      // reset expanded menu states
      setExpandedMenu({});
    } else {
      // Switch parent filter topic
      setSelectedFilterGroup(menuItem);
      // Make sure submenu is visible
      const newExpandedState: { [key: string]: boolean } = {};

      Object.keys(menu).forEach((key) => {
        newExpandedState[key] = false;
      });

      newExpandedState[menuItem] = true;
      setExpandedMenu(newExpandedState);
    }
  };
  const handleModalOpen = () => setShowFilterModal((previousState) => !previousState);
  const filterCount = prepareFilters().length;
  const filterGroupNames = Object.keys(menu);
  const subMenuData = selectedFilterGroup ? menu[selectedFilterGroup] : [];

  return (
    <div
      id="filter-menu-container"
      className="flex flex-col md:max-w-fit h-screen md:flex-row"
    >
      <div className="bg-pipbg-light md:w-80 md:h-full border-t md:border-l border-pipgrey-border order-last">
        <div id="search-container" className="relative p-2">
          <div className="flex items-center justify-between ">
            <div id="filter-summary-container" className="flex items-center m-2">
              <h2 className="font-semibold">Filters</h2>
              <Button
                id="filter-count"
                className="text-pipgreen-dark chip-green chip"
                label={`${filterCount} applied`}
                onClick={handleModalOpen}
              />
            </div>
            <Button
              id="clear-filters"
              className="text-sm text-pipstatus-alert hover:text-pipstatus-error transition-colors"
              label="Clear all"
              onClick={clearAllFilters}
            />
          </div>
          <label htmlFor="filter-search" className="flex flex-row items-center">
            <span className="sr-only">Search Filters</span>
            <div className="p-2 absolute">
              <span>{customIcons.searchIcon}</span>
            </div>
            <input
              type="search"
              id="filter-search"
              placeholder="Search filters"
              className="input-focused pe-2 ps-7"
            />
          </label>
        </div>

        <div id="filtergroup-conatiner" className="flex flex-col items-start w-full ">
          {filterGroupNames.map((filterGroupName) => (
            <div
              id={`${filterGroupName}-menu-item-container`}
              key={filterGroupName}
              className="flex flex-col w-full first:border-t"
            >
              <div className="flex flex-row items-center px-4 w-full h-10 border-b border-pipgrey-border">
                <button
                  className="flex flex-row space-x-2 items-center"
                  onClick={() => toggleSubmenu(filterGroupName)}
                  type="button"
                  aria-expanded={expandedMenu[filterGroupName] ? 'true' : 'false'}
                  aria-controls={`submenu-${filterGroupName.replace(/\s+|&/g, '-').trim()}`}
                >
                  <i className={`text-pipgreen-light ${expandedMenu[filterGroupName] ? 'fas fa-chevron-left' : 'fas fa-chevron-right'}`} />
                  <span>{filterGroupName}</span>
                </button>
              </div>

              {selectedFilterGroup === filterGroupName && (
              <div
                id="mobile-filter-menu"
                className="w-full md:hidden bg-gray-100 max-h-64 overflow-y-auto"
              >
                <SubFilterMenu menuData={subMenuData} title={selectedFilterGroup} />
              </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {showFilterModal && (
      <Modal
        isOpen={showFilterModal}
        onClose={handleModalOpen}
        hasCloseBtn
      >
        <span>modal!</span>
      </Modal>
      )}
      <div
        id="desktop-filter-menu"
        className={`bg-pipbg-light hidden md:block border-l border-pipgrey-border h-full overflow-y-auto transition-all duration-500 ease-in-out ${
          selectedFilterGroup ? 'w-80 opacity-100 visible' : 'w-0 opacity-0 invisible'
        }`}
      >
        <SubFilterMenu menuData={subMenuData} title={selectedFilterGroup} />
      </div>
    </div>
  );
}
