import Chip from '../chip';

export default function FormSummary({ object }: { object: any }) {
  const backgroundColour: Record<string, string> = {
    'In Debt with Council': 'green',
    'Tenancy Type': 'blue',
    'Household Type': 'green',
    Carer: 'purple',
    'Disability Status': 'yellow',
  };
  return (
    <div className="flex flex-wrap lg:flex-col gap-1 m-2">
      { Object.entries(object).map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((string) => <Chip key={string} text={string} colour={backgroundColour[key]} />);
        }
        return null;
      })}
    </div>
  );
}
