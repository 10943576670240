import { SnapshotResponse } from '../../api-models';
import { HTTPMethod } from '../enums';
import { AdapterInterface } from '../global';
import BaseAdapter from './baseAdapter';

export default class GetLatestSnapshot extends BaseAdapter implements AdapterInterface {
  rawResponse: SnapshotResponse;

  async adaptResponse(): Promise<string> {
    await this.fetchApiResponse('/api/latest-snapshot', HTTPMethod.Get);
    return this.rawResponse.snapshot;
  }
}
