import { ImpactTheme, ImpactThemeEnum, ImpactThemeResponse } from '../../api-models/types.gen';
import { snakeCase } from '../../utils/formatters';
import { HTTPMethod } from '../enums';
import { AdapterInterface, ImpactThemeInterface } from '../global';
import BaseAdapter from './baseAdapter';

export default class PostImpactThemeData extends BaseAdapter implements AdapterInterface {
  rawResponse: ImpactThemeResponse;

  modifiedRequest: ImpactTheme;

  async adaptResponse(request: string): Promise<ImpactThemeInterface> {
    const formattedRequest = this.adaptRequest(request);
    await this.fetchApiResponse('/api/impact-theme-data', HTTPMethod.Post, formattedRequest);
    return {
      headlineStatistics: this.rawResponse.headline_statistics.map((statistic) => ({
        title: statistic.title,
        displayNumber: statistic.display_number,
        displayVariance: statistic.display_variance,
      })),
      graphDetails: {
        title: this.rawResponse.graph_details.title,
        categoricalLabel: this.rawResponse.graph_details.categorical_label,
        valueLabel: this.rawResponse.graph_details.value_label,
        data: this.rawResponse.graph_details.data,
      },
    };
  }

  adaptRequest(request: string): ImpactTheme {
    this.modifiedRequest = { impact_theme_title: snakeCase(request) as ImpactThemeEnum };
    return this.modifiedRequest;
  }
}
