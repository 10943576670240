/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/exhaustive-deps */
import { Form, redirect, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SplitLayout from '../components/splitLayout';
import List from '../components/campaigns/list';
import LoadingModal from '../components/loadingModal';
import {
  CampaignStore, LetterTemplate, SMSSenderInformation, SMSTemplate,
} from '../types/campaigns.type';
import Modal from '../components/modal';
import customIcons from '../utils/icons';
import Steps from '../components/campaigns/steps';
import campaignSteps, { sendLetterStep } from '../utils/campaignSteps';
import useCampaignStore from '../components/campaigns/campaignStore';
import { formatSnapshot } from '../utils/formatters';
import { Channel } from '../types/enums';
import SMS from '../components/campaign-confirmation/smsPreview';
import LetterPreview from '../components/campaign-confirmation/letterPreview';
import SMSGallery from '../components/campaign-confirmation/smsGallery';
import useRequestState from '../hooks/useRequestState';
import PostLetterTemplates from '../types/adapters/letterTemplate';
import GetSmsTemplates from '../types/adapters/smsTemplate';
import PostAddCampaign from '../types/adapters/addCampaign';
import Button from '../components/buttons/baseButton';

export async function action({ params }: { params: { campaignId: string, impactTheme: string } }) {
  const { campaignId } = params;

  const addCampaignAdapter = new PostAddCampaign();
  await addCampaignAdapter.adaptResponse(campaignId);
  return redirect('/campaigns');
}

export default function CampaignConfirmation() {
/*
campaigns = array of all campaigns store in state store
campaign data = ALL campaign info that is collected and generated (generated being the id and created at date)
campaign information = is the campaign info that is collected from the user
*/
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSms, setShowSms] = useState(false);
  const [smsText, setSmsText] = useState('');
  const [showLetter, setShowLetter] = useState(false);
  const [letterUrl, setLetterUrl] = useState('');
  const [displayValue, setDisplayValue] = useState('Please select a template');
  const displayExcludeHouseholds = false;

  const params = useParams();
  const campaigns = useCampaignStore((state) => state.campaigns);
  const setTemplate = useCampaignStore((state) => state.setTemplate);
  const campaignData = campaigns.find((campaign) => campaign.id === params.campaignId) as CampaignStore;

  const {
    data: smsTemplates,
    handleRequest: handleSmsRequest,
    FallbackWrapper: FallbackSmsWrapper,
  } = useRequestState<SMSSenderInformation>();
  const {
    data: letterTemplates,
    handleRequest: handleLetterRequest,
    FallbackWrapper: FallbackLetterWrapper,
  } = useRequestState<LetterTemplate[]>();

  const {
    campaignName, campaignType, snapshot, excludeHouseholds, excludeHouseholdsByMonth, communicationChannel, stagger,
  } = campaignData;

  const campaign = {
    'Campaign Name': campaignName,
    'Campaign Type': campaignType,
    'LIFT Snapshot': formatSnapshot(snapshot, 'long'),
  };
  const titleObject = {
    title: 'Campaign Manager',
    icon: customIcons.sendIcon,
  };

  const steps = [...campaignSteps, sendLetterStep];
  const modalMessage = `This campaign will be be sent to Policy in Practice and drafts generated for review.
  We will be in touch to confirm details of the campaign before it is sent to residents. Would you like to proceed?`;

  const handleModalOpen = () => {
    setModalOpen((previousState) => !previousState);
  };
  const handleLoading = () => {
    setLoading(true);
  };
  const excludeHouseholdsText = (numberOfMonths: string) => (
    <>
      Households contacted in the last
      <strong>{` ${numberOfMonths?.toLowerCase()}`}</strong>
      will be excluded`
    </>
  );
  const includeHouseholdsText = 'Included previously contacted households';

  const handleSms = (template: SMSTemplate) => {
    setTemplate(campaignData.id, template.id);
    setSmsText(template.template);
    setShowSms(true);
  };

  const handleLetter = (event: any, campaignId: string) => {
    const value = JSON.parse(event.target.value);
    const { id, url, name } = value;
    setDisplayValue(name);
    setTemplate(campaignId, id);
    setLetterUrl(url);
    setShowLetter(true);
  };

  useEffect(() => {
    if (!campaignData) return;
    const loadTemplates = async (commsChannel: string, benefitType: string) => {
      if (commsChannel === Channel.SMS) {
        const GetSmsTemplatesInstance = new GetSmsTemplates();
        const getSmsTemplates = GetSmsTemplatesInstance.adaptResponse();
        await handleSmsRequest(getSmsTemplates);
      }
      if (commsChannel === Channel.Letter) {
        const postLetterTemplateInstance = new PostLetterTemplates();
        const postLetterTemplates = postLetterTemplateInstance.adaptResponse(benefitType);
        await handleLetterRequest(postLetterTemplates);
      }
    };

    loadTemplates(communicationChannel, campaignType);
  }, [communicationChannel, campaignType]);

  // function to conditionally render content based on loading state and modals
  const renderContent = () => {
    if (isModalOpen) {
      if (loading) {
        return (<LoadingModal message="loading" />);
      }

      return (
        <Modal isOpen={isModalOpen} hasCloseBtn onClose={handleModalOpen}>
          <span className="text-6xl text-pipgreen">{customIcons.mailWarningIcon}</span>
          <h2 className="text-xl font-bold text-pipgreen-dark">Please Confirm</h2>
          <p className="font-thin whitespace-wrap">{modalMessage}</p>
          <div className="w-full flex justify-between p-2">
            <button
              type="button"
              id="cancelModalButton"
              className="p-1 w-1/2 m-2 shadow-inner bg-white rounded-md text-pip-background border"
              onClick={handleModalOpen}
            >
              Cancel
            </button>
            <Form method="post" className="w-1/2" onSubmit={handleLoading} replace>
              <button
                type="submit"
                className="w-full p-1 m-2 bg-pipgreen rounded-md text-white"
              >
                Send for Review
              </button>
            </Form>
          </div>
        </Modal>
      );
    } return (
      <>
        <h1 className="m-2 p-4 text-header font-medium text-pipgreen-dark border-b-2">Preview Campaign</h1>
        <SplitLayout leftWeight="" rightWeight="">

          <Steps
            titleObject={titleObject}
            steps={steps}
            selected={{
              campaignName, campaignType, snapshot, excludeHouseholds, communicationChannel, stagger,
            }}
          />

          <div className="flex flex-grow flex-col overflow-scroll">
            <List campaign={campaign} />
            {displayExcludeHouseholds && (
            <p className="text-sm text-center">
              {excludeHouseholds && excludeHouseholdsByMonth ? excludeHouseholdsText(excludeHouseholdsByMonth) : includeHouseholdsText}
            </p>
            )}
            <FallbackSmsWrapper loadingMessage="Loading sms template options">
              {!showSms && Array.isArray(smsTemplates?.smsTemplates) && communicationChannel === Channel.SMS ? (
                <SMSGallery smsTemplateList={smsTemplates.smsTemplates} handleSms={handleSms} />) : ''}
            </FallbackSmsWrapper>
            {showSms && communicationChannel === Channel.SMS ? <SMS sender={smsTemplates?.smsSenderId as string} message={smsText} /> : ''}
            <FallbackLetterWrapper loadingMessage="Loading template options">

              {communicationChannel === Channel.Letter && (
                <div>
                  <label htmlFor="letter-template-dropwdown" className="flex flex-col p-2 justify-between font-thin w-full">
                    <span className="text-sm">Choose Letter Template</span>
                    <select
                      id="letter-template-dropdown"
                      value={displayValue}
                      name="letter-template-dropdown"
                      onChange={(e) => handleLetter(e, campaignData.id)}
                      className="flex-1 p-1 rounded-md align-middle bg-slate-50 border border-pipgrey-light text-pipgrey"
                    >
                      <option value="">{displayValue}</option>
                      {Array.isArray(letterTemplates) && letterTemplates.map((letterTemplate: LetterTemplate) => (
                        <option
                          key={letterTemplate.id}
                          value={JSON.stringify({
                            id: letterTemplate.id,
                            url: letterTemplate.letterTemplateSignedUrl,
                            name: letterTemplate.displayText,
                          })}
                        >
                          {letterTemplate.displayText}
                        </option>

                      ))}
                    </select>
                  </label>
                </div>
              )}
            </FallbackLetterWrapper>

            {showLetter && communicationChannel === Channel.Letter ? <LetterPreview url={letterUrl} /> : ''}
          </div>
        </SplitLayout>
        <div className="w-full m-2 flex justify-center">
          {smsText || letterUrl ? <Button id="send-campaign-for-review" className="button-green" label="Send for Review" onClick={handleModalOpen} /> : <span>Please choose a template</span>}
        </div>
      </>

    );
  };
  return (
    <div className="h-screen flex flex-col w-full p-4">
      {renderContent()}
    </div>
  );
}
