import { Form, useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import BarGraph from './barGraph';
import FallbackGraph from './fallbackGraph';
import MultiCheckboxSelect from './multiCheckboxSelect';
import { ImpactThemeInterface, Statistic, Theme } from '../../types/global';
import Subtext from './subtext';
import HeadlineCard from './headlineCard';
import FormSummary from './formSummary';
import SplitLayout from '../splitLayout';
import { adminAccess } from '../../utils/authRoleWrap';
import { snakeCase } from '../../utils/formatters';
import PostImpactThemeData from '../../types/adapters/impactTheme';
import SendToCalculatorButton from '../buttons/sendToCalculator';
import Button from '../buttons/baseButton';
import DownloadButton from '../buttons/downloadButton';
import customIcons from '../../utils/icons';
import useFilterStore from '../streetview/filterStore';
import useRequestState from '../../hooks/useRequestState';

export default function ImpactCard({ theme }: { theme: Theme }) {
  const navigate = useNavigate();
  const selected = useFilterStore((state) => state.getActiveFilters());

  const { title, filterOptions, socialProof } = theme;
  const {
    data: impactTheme,
    handleRequest: handleImpactThemeRequest,
    FallbackWrapper: FallbackImpactWrapper,
  } = useRequestState<ImpactThemeInterface>();
  const themeTitle = snakeCase(title);

  const themeData = useMemo(() => selected[themeTitle] || {}, [selected, themeTitle]);

  useEffect(() => {
    const getImpactData = async (): Promise<void> => {
      const impactThemeInstance = new PostImpactThemeData();
      const impactThemeResponse = impactThemeInstance.adaptResponse(title);
      await handleImpactThemeRequest(impactThemeResponse);
    };

    getImpactData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, filterOptions]);

  const AdminActionButton = adminAccess(Button);

  return (
    <div className="w-full">
      <div id="impact-card" className="h-max hover:shadow-sideShadow rounded-sm m-2 p-2 w-full">
        <div id="impact-card-header" className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col m-2">
            <p className="font-thin text-xs"> definition of impact theme</p>
          </div>
          <div className="flex flex-row justify-around gap-2 text-sm">
            <SendToCalculatorButton
              data={themeData}
              className="button-default"
            />
            <AdminActionButton
              id="create-campaign"
              className="button-default"
              label="Create Campaign"
              icon={customIcons.sendIcon}
              onClick={() => navigate('/campaigns/new')}
            />
            <DownloadButton
              id={`${title}-export-csv`}
              className="button-default"
              endpoint="/api/temporary-csv"
              data={selected}
            />
          </div>
        </div>
        <SplitLayout rightWeight="w-1/2" leftWeight="w-1/2">
          <div id="impact-card-left-side" className="flex flex-col justify-between">
            <FallbackImpactWrapper loadingMessage="">
              <div id="impact-card-headline-container" className="m-2 h-fit flex flex-col md:flex-row justify-evenly">
                {impactTheme?.headlineStatistics ? (
                  impactTheme.headlineStatistics.map((headline: Statistic) => (
                    <HeadlineCard
                      key={headline.title}
                      text={headline.title}
                      displayNumber={headline.displayNumber}
                      variance={headline.displayVariance}
                    />
                  ))

                ) : ''}
              </div>
              {impactTheme?.graphDetails.data ? <BarGraph graphData={impactTheme?.graphDetails.data} title="" valueLabel={impactTheme.graphDetails.valueLabel} categoricalLabel={impactTheme.graphDetails.categoricalLabel} /> : <FallbackGraph />}
              <Subtext text={socialProof.text} link={socialProof.link} />
            </FallbackImpactWrapper>
          </div>
          <div id="impact-card-right-side" className="flex flex-col md:flex-row justify-between w-full">
            <div className="w-9/12">
              <Form className="">
                {filterOptions.map(({ label, options }) => (
                  <MultiCheckboxSelect mutliSelectOptions={options} key={`${title}-${label}`} label={label} title={title} />
                ))}
              </Form>
            </div>
            <div className="flex flex-col lg:flex-row">
              <FormSummary object={themeData} />
            </div>
          </div>
        </SplitLayout>
      </div>
    </div>
  );
}
