import { CampaignDetails, CampaignDetailsResponse } from '../../api-models';
import { getCampaign } from '../../utils/campaign';
import { CampaignInformation } from '../campaigns.type';
import { Channel, HTTPMethod } from '../enums';
import { AdapterInterface } from '../global';
import BaseAdapter from './baseAdapter';

export default class PostAddCampaign extends BaseAdapter implements AdapterInterface {
  rawResponse: CampaignDetailsResponse[];

  modifiedRequest: CampaignDetails;

  async adaptResponse(request: string): Promise<CampaignInformation[]> {
    const formattedRequest = this.adaptRequest(request);

    await this.fetchApiResponse('/api/add-campaign', HTTPMethod.Post, formattedRequest);

    return this.rawResponse.map((campaign) => ({
      id: campaign.id,
      campaignName: campaign.name,
      campaignType: campaign.benefit_name,
      communicationChannel: campaign.channel,
      snapshot: campaign.snapshot,
      status: campaign.status,
      numberOfRecipients: campaign.number_of_recipients,
      createdBy: campaign.created_by,
    }));
  }

  static createDisplayText(url: string): string {
    const urlArray = new URL(url).pathname.split('/');
    const fileName = urlArray.pop()?.split('.')[0] ?? '';
    const benefitName = urlArray.pop()?.split('_').join(' ').replace(/(^\w|\s\w)/g, (benefit) => benefit.toUpperCase()) ?? '';
    return `${benefitName}: ${fileName}`;
  }

  adaptRequest(campaignId: string): CampaignDetails {
    const campaignData = getCampaign(campaignId);

    this.modifiedRequest = {
      campaign_name: campaignData.campaignName,
      benefit_name: campaignData.campaignType?.split(' ').join('_').toLowerCase(),
      snapshot: campaignData.snapshot,
      channel_name: campaignData.communicationChannel === Channel.Letter ? Channel.BackendLetter : campaignData.communicationChannel.toLowerCase(),
      stagger: campaignData.stagger, // set to false in campaign store until stagger is implemented
      sms_template_id: campaignData.smsTemplateId,
      template_s3_key: campaignData.letterTemplateId,
    };
    return this.modifiedRequest;
  }
}
