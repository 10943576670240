/* eslint-disable react/no-array-index-key */
import { CampaignSelection } from '../../types/campaigns.type';
import { StepsProps } from '../../types/global';
import customIcons from '../../utils/icons';
import InformationText from '../InformationText';

export default function Steps(
  { steps, titleObject, selected }:
  { steps: StepsProps[],
    titleObject: {
      title: string,
      icon: JSX.Element },
    selected: CampaignSelection },
) {
  const stepDone = (campaignState: CampaignSelection, item: StepsProps) => {
    if (item.step === 'Choose name and campaign type' && campaignState.campaignName && campaignState.campaignType !== '') {
      return customIcons.checkCircleIcon;
    }
    if (item.step === 'Choose Communication Channel' && campaignState.communicationChannel !== '') {
      return customIcons.checkCircleIcon;
    }
    return item.icon;
  };

  return (
    <div className="overflow-auto">
      <div className="flex flex-row items-center ">
        <span className="m-2 p-2 text-2xl text-pipgreen">{titleObject.icon}</span>
        <h2 className="text-2xl font-medium">{titleObject.title}</h2>
      </div>
      <p className="m-2 p-2 font-thin">
        This tool will enable you to contact your residents and run benefit take up campaigns.
        {' '}
      </p>
      {selected && steps.map((item, index) => (
        <div key={`${item.step}-${index}`} className="flex flex-row items-center mx-2 px-2 text-">
          <div className="w-fit h-fit border-2 rounded p-2 text-xl text-pipgreen">{stepDone(selected, item)}</div>
          <InformationText title={item.step} subtitle={item.info} />
        </div>
      ))}
    </div>
  );
}
