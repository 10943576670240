import { Filters } from '../../types/global';
import MultiCheckboxSelect from '../home/multiCheckboxSelect';

export default function SubFilterMenu({ title, menuData }: { title: string, menuData: Filters[] }) {
  return (
    <div className="w-full">
      {menuData.map(({ filterValues, label, type }) => (
        <div className="p-4 w-full" key={`submenu-${label}`}>
          <div className="flex flex-col">
            <ul>
              <li className="flex flex-col border-b border-pipgrey-border py-2">
                {type === '' ? <MultiCheckboxSelect mutliSelectOptions={filterValues} label={label} title={title} /> : ''}
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
}
