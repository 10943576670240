import { useLoaderData } from 'react-router-dom';
import HeadlineCard from '../components/home/headlineCard';
import SplitLayout from '../components/splitLayout';
import { DisplayCampaignSummary, DisplayCampaignSummaryInformation } from '../types/campaigns.type';
import PostCampaignSummary from '../types/adapters/campaignSummary';
import BarGraph from '../components/home/barGraph';
import FallbackGraph from '../components/home/fallbackGraph';
import DownloadButton from '../components/buttons/downloadButton';

export function loader({ params }: { params: { id: number } }) {
  const { id } = params;
  const campaignSummaryInstance = new PostCampaignSummary();
  return campaignSummaryInstance.adaptResponse(id);
}

export default function CampaignSummary() {
  const campaignData = useLoaderData() as DisplayCampaignSummary;
  const {
    reportEnds, campaignDetails, campaignImpact, graphDetails,
  } = campaignData;
  return (
    <div id="campaign-container" className="flex flex-col w-full p-4 overflow-auto">
      <div className="flex flex-row justify-between border-b-2 items-center">
        <h1 id="campaign-summary-header" className="m-2 p-4 text-header font-medium text-pipgreen-dark ">Campaign Report</h1>
      </div>
      <div className="h-fit">
        <div id="campaign-details-container" className="rounded-xl bg-pipbg-mobile flex flex-col md:flex-row m-2 justify-between">
          <div className="flex flex-col mx-4 p-2 justify-between">
            <h2 className="font-semibold text-lg">Campaign Details</h2>
            <div>
              {campaignDetails.map((result: DisplayCampaignSummaryInformation) => (
                <p key={result.text} className="">{`${result.text}: ${result.value}`}</p>
              ))}
            </div>
          </div>
          <p className="mx-4 p-2">
            <span>{reportEnds}</span>
          </p>

        </div>
        <div id="campaign-impact-header" className="flex flex-col md:flex-row mx-4 px-2 align-middle md:items-center justify-between">
          <h2 className="mx-2 font-semibold text-lg">Campaign Impact</h2>
          <div className="flex flex-col w-40 lg:items-end m-2">
            <DownloadButton
              id="campaign-impact"
              className="button-default"
              endpoint="/api/campaign-summary-csv"
              data={{ id: campaignData.id }}
            />
          </div>
        </div>
        <SplitLayout leftWeight="border-none shadow-none" rightWeight="border-none shadow-none">
          <div id="campaign-impact-headlines" className="m-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {campaignImpact.map((result: DisplayCampaignSummaryInformation) => (
              <HeadlineCard
                key={result.text}
                text={result.text}
                displayNumber={result.value.toString()}
              />
            ))}
          </div>
          <div id="campaign-impact-graph">
            {graphDetails.data.length > 0
              ? (
                <BarGraph
                  graphData={graphDetails.data}
                  title={graphDetails.title}
                  categoricalLabel={graphDetails.categoricalLabel}
                  valueLabel={graphDetails.valueLabel}
                />
              ) : <FallbackGraph />}
          </div>
        </SplitLayout>
      </div>
    </div>
  );
}
