enum HouseholdType {
  Single = 'Single',
  SingleParentWith1Child = 'Single Parent-with 1 child',
  SingleParentWith2Children = 'Single Parent-with 2 children',
  SingleParentWith2PlusChildren = 'Single Parent-with 2+ children',
  Couple = 'Couple',
  CoupleParentWith1Child = 'Couple Parent-with 1 child',
  CoupleParentWith2Children = 'Couple Parent-with 2 children',
  CoupleParentWith2PlusChildren = 'Couple Parent-with 2+ children',

}

enum HouseholdLabel {
  Single = 'Single',
  SingleParentWith1Child = 'Single Parent-with 1 child',
  SingleParentWith2Children = 'Single Parent-with 2 children',
  SingleParentWith2PlusChildren = 'Single Parent-with 2+ children',
  Couple = 'Couple',
  CoupleParentWith1Child = 'Couple Parent-with 1 child',
  CoupleParentWith2Children = 'Couple Parent-with 2 children',
  CoupleParentWith2PlusChildren = 'Couple Parent-with 2+ children',

}

enum DisabilityType {
  HasDisability = 'Has disability',
  NoDisability = 'No disability',
}

enum DisabilityLabel {
  HasDisability = 'Has disability',
  NoDisability = 'No disability',

}

enum CarerType {
  IsCarerHousehold = 'Is a carer household',
  NotCarerHousehold = 'Not a carer household',
}

enum CarerLabel {
  IsCarerHousehold = 'Is a carer household',
  NotCarerHousehold = 'Not a carer household',
}

enum TenancyType {
  CouncilTenant = 'Council Tenant',
  SocialTenant = 'Social Tenant',
  PrivateTenant = 'Private Tenant',
  TemporaryAccommodation = 'Temporary Accommodation',
  SupportedHousing = 'Supported Housing',
  OwnerOccupier = 'Owner Occupier',
}

enum TenancyLabel {
  CouncilTenant = 'Council Tenant',
  SocialTenant = 'Social Tenant',
  PrivateTenant = 'Private Tenant',
  TemporaryAccommodation = 'Temporary Accommodation',
  SupportedHousing = 'Supported Housing',
  OwnerOccupier = 'Owner Occupier',
}

enum DebtType {
  HasDebt = 'Has debt',
  NoDebt = 'No debt',
}
enum DebtLabel {
  HasDebt = 'Has debt',
  NoDebt = 'No debt',

}

const houseHoldOptions = [{ value: HouseholdType.Single, label: HouseholdLabel.Single },
  { value: HouseholdType.SingleParentWith1Child, label: HouseholdLabel.SingleParentWith1Child },
  { value: HouseholdType.SingleParentWith2Children, label: HouseholdLabel.SingleParentWith2Children },
  { value: HouseholdType.SingleParentWith2PlusChildren, label: HouseholdLabel.SingleParentWith2PlusChildren },
  { value: HouseholdType.Couple, label: HouseholdLabel.Couple },
  { value: HouseholdType.CoupleParentWith1Child, label: HouseholdLabel.CoupleParentWith1Child },
  { value: HouseholdType.CoupleParentWith2Children, label: HouseholdLabel.CoupleParentWith2Children },
  { value: HouseholdType.CoupleParentWith2PlusChildren, label: HouseholdLabel.CoupleParentWith2PlusChildren },
];

const disabilityOptions = [{ value: DisabilityType.HasDisability, label: DisabilityLabel.HasDisability },
  { value: DisabilityType.NoDisability, label: DisabilityLabel.NoDisability }];

const carerOptions = [{ value: CarerType.IsCarerHousehold, label: CarerLabel.IsCarerHousehold }, {
  value: CarerType.NotCarerHousehold, label: CarerLabel.NotCarerHousehold,

}];

const tenancyOptions = [{ value: TenancyType.CouncilTenant, label: TenancyLabel.CouncilTenant },
  { value: TenancyType.SocialTenant, label: TenancyLabel.SocialTenant },
  { value: TenancyType.PrivateTenant, label: TenancyLabel.PrivateTenant },
  { value: TenancyType.TemporaryAccommodation, label: TenancyLabel.TemporaryAccommodation },
  { value: TenancyType.SupportedHousing, label: TenancyLabel.SupportedHousing },
  { value: TenancyType.OwnerOccupier, label: TenancyLabel.OwnerOccupier },
];

const debtOptions = [{ value: DebtType.HasDebt, label: DebtLabel.HasDebt }, {
  value: DebtType.NoDebt, label: DebtLabel.NoDebt,

}];

const themeObject = [
  {
    title: 'Benefit Takeup',
    socialProof: {
      text: 'Luton Council secures £4.6 million for older people by boosting Pension Credit take up by 70%',
      link: 'https://policyinpractice.co.uk/luton-council-secures-4-6-million-for-older-people-by-boosting-pension-credit-take-up-by-70/',
    },
    filterOptions: [{
      label: 'Household Type',
      options: houseHoldOptions,
    }, {
      label: 'Disability Status',
      options: disabilityOptions,
    },
    {
      label: 'Carer',
      options: carerOptions,
    }, {
      label: 'Tenancy Type',
      options: tenancyOptions,
    }],
  },
  {
    title: 'Debt Reduction',
    socialProof: {
      text: 'East Riding of Yorkshire Council grew the income of 15,700 households by nearly £13 million.',
      link: 'https://policyinpractice.co.uk/how-the-revenues-and-benefits-team-at-east-riding-of-yorkshire-council-grew-the-income-of-15700-households-by-nearly-13-million/',
    },
    filterOptions: [{
      label: 'Household Type',
      options: houseHoldOptions,
    }, {
      label: 'Disability Status',
      options: disabilityOptions,
    },
    {
      label: 'Carer',
      options: carerOptions,
    }, {
      label: 'Tenancy Type',
      options: tenancyOptions,
    }],
  },
  {
    title: 'Homelessness Prevention',
    socialProof: {
      text: 'Richmond and Wandsworth Councils leverage data to identify over 3,500 low income families eligible for energy support packages to support them through the winter.',
      link: 'https://policyinpractice.co.uk/richmond-and-wandsworth-councils-client-story/',
    },
    filterOptions: [{
      label: 'Household Type',
      options: houseHoldOptions,
    }, {
      label: 'Disability Status',
      options: disabilityOptions,
    },
    {
      label: 'Carer',
      options: carerOptions,
    }, {
      label: 'Tenancy Type',
      options: tenancyOptions,
    }, {
      label: 'In Debt with Council',
      options: debtOptions,
    }],
  },
];

export default themeObject;
