import { useLoaderData, useNavigate } from 'react-router-dom';
import Table from '../components/table';
import { adminAccess } from '../utils/authRoleWrap';
import GetAllCampaigns from '../types/adapters/allCampaigns';
import { DisplayCampaignInformation } from '../types/campaigns.type';
import { IconColumn } from '../types/global';
import Button from '../components/buttons/baseButton';
import DownloadButton from '../components/buttons/downloadButton';

export async function loader() {
  const allCampaignInstance = new GetAllCampaigns();
  const campaignData = await allCampaignInstance.adaptResponse();
  return campaignData;
}
const actionColumns: Record<string, IconColumn> = {
  Export: {
    title: 'Export',
    component: ({ id, data }) => <DownloadButton id={`campaign-table${id}`} className="button-default" data={data} endpoint="/api/temporary-csv" />,
  },
};

export default function Campaigns() {
  const allCampaigns = useLoaderData() as DisplayCampaignInformation[];
  const navigate = useNavigate();
  const CreateCampaignButton = adminAccess(Button);
  const campaignButton = (<CreateCampaignButton id="create-new-campaign-button" className="button-green" label="Create new campaign" onClick={() => navigate('/campaigns/new')} />);

  return (
    allCampaigns.length > 0
      ? (
        <div id="campaign-container" className="flex flex-col w-full p-4 overflow-auto">
          <div className="flex flex-row justify-between border-b-2 items-center">
            <h1 id="campaign-header" className="m-2 p-4 text-header font-medium text-pipgreen-dark ">Campaigns</h1>
            {campaignButton}
          </div>
          <Table tableObject={allCampaigns} actionColumns={actionColumns} title="Campaigns" count="campaign" />
        </div>
      ) : (
        <div className="flex flex-col overflow-auto sjustify-center items-center absolute inset-0 m-auto p-4 border-0 rounded-md shadow-lg bg-white h-72 w-1/2 text-center">
          <p className="m-2 p-2">You have not started any campaigns yet.</p>
          {campaignButton}
        </div>
      )
  );
}
