import { DisplayUser } from '../../api-models';
import { OrganisationTableProps } from '../core.type';
import { AdapterInterface } from '../global';
import BaseAdapter from './baseAdapter';
import { createDisplayObject } from '../../utils/formatters';
import { HTTPMethod } from '../enums';

export default class GetOrganisationUsers extends BaseAdapter implements AdapterInterface {
  rawResponse: DisplayUser[];

  async adaptResponse(): Promise<OrganisationTableProps[]> {
    await this.fetchApiResponse('/api/organisation-users', HTTPMethod.Get);
    return this.displayResponse();
  }

  displayResponse(): OrganisationTableProps[] {
    return this.rawResponse.map((user) => createDisplayObject(user));
  }
}
