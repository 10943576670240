// @refresh reset
import { useLoaderData } from 'react-router-dom';
import LoginForm from '../components/login/loginForm';
import { OrganisationNames } from '../types/core.type';

export function loader() {
  // TO DO: Get list of customer names from backend - async function call
  try {
    const testOrgs = ['policy in practice'];
    return { organisationNames: testOrgs };
  } catch (error) {
    console.error('Error in loader:', error);
    throw new Error('Error loading organizations');
  }
}

export default function Login() {
  const data = useLoaderData() as OrganisationNames;
  const { organisationNames } = data || {};
  if (!data || !data.organisationNames) {
    console.error('No organisationNames data available');
  }

  return (
    <div className="h-screen flex flex-wrap sm:flex-col" id="login-container" aria-label="login-container">
      <div className="flex flex-col items-center justify-center h-screen w-full sm:w-1/2 ">
        <main className="mt-auto w-9/12 p-4 flex flex-col items-center justify-center">
          <img src="/pip_green_logo.svg" className="m-4" alt="policy in practice" />
          <h1 className="text-center font-bold p-2 text-lg">Login to your account</h1>
          <p className="text-center text-sm  mt-0 pt-0 m-2 p-2 text-pipgrey">
            Welcome back!
            <br />
            Please choose your organisation.
          </p>
        </main>
        { organisationNames ? <LoginForm organisationNames={organisationNames} /> : ''}
        <footer className="text-xs mt-auto m-2 text-pipgrey">© LIFT powered by Policy in Practice</footer>
      </div>
      <figure id="login-marketing" className="sm:w-1/2 w-full h-screen hidden sm:flex">
        <img src="/peopleoncomputer.png" className="object-cover h-screen w-screen" alt="two people sitting on a couch looking at laptop" />
      </figure>
    </div>
  );
}
