enum Role {
  SuperAdmin = 'SuperAdmin',
  OrganisationAdmin = 'OrganisationAdmin',
  Viewer = 'Viewer',
}

export enum Channel {
  BackendLetter = 'print_and_post',
  Letter = 'Letter',
  SMS = 'SMS',
}
export enum HTTPMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

export default Role;
