import { ComponentProps } from '../../types/core.type';

export type ButtonProps = ComponentProps & {
  label?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
};
export default function Button({
  id, children, onClick, className = '', label = '', icon = null, isDisabled = false,
}: ButtonProps) {
  return (
    <button
      id={id}
      type="submit"
      onClick={onClick}
      disabled={isDisabled}
      className={`${className} font-thin leading-normal flex justify-center items-center gap-2 ps-2 pe-2`}
    >
      {icon ? <i>{icon}</i> : null}
      {label ? <span>{label}</span> : null}
      {children}
    </button>
  );
}
