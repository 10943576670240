import { createContext, useContext } from 'react';
import { User } from '../types/core.type';

type UserState = {
  user: User | null;
  setUser: (user: User | null) => void;
};
export const AuthContext = createContext<UserState | undefined>(undefined);

export default function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  if (context.user === null) {
    throw new Error('user is null - useAuth must be used within an AuthProvider');
  }
  return context.user;
}
